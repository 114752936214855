import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loadering from "../../assets/images/loading.gif";
import {
  GetAllSearchTags,
  SearchTagsAll,
  SearchTagsAllById,
} from "../services/SearchTags";
import {
  Box,
  CircularProgress,
  FormControlUnstyledContext,
  Pagination,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { UserapiData } from "../services/UserApi";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import moment from "moment";
import FormDialog from "./dialog";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

function Tags(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(props.open);
  const [User, setUser] = useState([]);
  const [SelectUsers, setSelectUser] = useState();
  const [catID, setcATid] = useState("");
  const [responseTags, setresponseTags] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [fileterbool, setFilterBool] = useState(false);
  const [filter, setFilter] = useState(false);
  const handleOpen = () => setFilterBool(true);
  const handleClose = () => setFilterBool(false);
  const [lDate, setLDate] = useState(null);
  const [fDate, setFDate] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  const downloadData = [];

  useEffect(() => {
    UserapiData()
      .then(({ data }) => {
        let obj = {
          _id: "allCategory",
          firstName: "All Users",
        };
        let temp = [...data?.data?.users];
        temp.unshift(obj);

        setUser(temp);
      })
      .catch((err) => {});
    searchtgsa();
  }, []);

  const findId = (value) => {
    let id = "";
    let clone = [...User];
    clone.forEach((element) => {
      let findIndexId = clone.findIndex((ii) => ii?.firstName == value);
      id = clone[findIndexId]._id;
      setcATid(id);
    });
    if (id === "allCategory") {
      searchtgsa();
    } else {
      searchtagsById(id);
    }
  };

  const searchtgsa = () => {
    // setresponseTags([]);
    // setOpen(true);
    if (lDate && fDate) {
      setOpen(true);
      SearchTagsAll(currentPage, lDate, fDate)
        .then(({ data }) => {
          if (data) {
            setCount(data?.data?.count);
            setresponseTags(data?.data?.searchKeywords);
          }
          setOpen(false);
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      setOpen(true);
      SearchTagsAll(currentPage)
        .then(({ data }) => {
          if (data) {
            setCount(data?.data?.count);
            setresponseTags(data?.data?.searchKeywords);
          }
          setOpen(false);
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  };

  useEffect(() => {
    setSelectUser("All Users");
    searchtgsa();
  }, [currentPage, lDate, fDate]);

  const searchtagsById = (id) => {
    setresponseTags([]);

    // setOpen(true);

    SearchTagsAllById(id)
      .then(({ data }) => {
        if (data) {
          setCount(data?.data?.count);
          setresponseTags(data.data?.data);
        }
        setOpen(false);
      })
      .catch((err) => {});
  };

  const downloadExcel = () => {
    setDownloadLoader(true);
    GetAllSearchTags()
      .then(({ data: { data } }) => {
        downloadData.length = 0;
        data?.map((items, index) => {
          const tempObj = {
            Sr_No: index + 1,
            Time: moment(items?.createdAt).format("h:mm:ss a"),
            Date: moment(items?.createdAt).format("Do MMM YY"),
            FirstName: items?.userId?.firstName,
            LastName: items?.userId?.lastName,
            Gender: items?.userId?.gender,
            PostalCode: items?.userId?.zipCode,
            Age: items?.userId?.age,
            Tags: items?.searchQuery,
          };
          downloadData.push(tempObj);
        });
        setOpen(false);

        const workSheet = XLSX.utils.json_to_sheet(downloadData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Tags");

        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        XLSX.writeFile(workBook, "Tags.xlsx");
      })
      .finally(() => {
        setDownloadLoader(false);
      });
  };

  const handleFilter = (start, end) => {
    setLDate(end);
    setFDate(start);
    setFilter(true);
  };

  const resetFilter = () => {
    setLDate(null);
    setFDate(null);
    setFilter(false);
    setOpen(true);
  };

  return (
    <div>
      <div className="users_table">
        <div className="titlee">
          <h2>Search Tags</h2>
        </div>
        {open ? (
          <div className="loaadds">
            <img src={Loadering} className="loaderr" />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <div className="d-flex justify-content-between align-items-center px-3">
                <div className="seleeect w-25">
                  <TextField
                    id="outlined-select-currency"
                    className="flo muiSelection"
                    select
                    label="User"
                    value={SelectUsers}
                    onChange={(e) => {
                      setSelectUser(e.target.value);
                      findId(e.target.value);
                    }}
                  >
                    {User?.map((option) => (
                      <MenuItem key={option.id} value={option?.firstName}>
                        {option?.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="excle_btn">
                  {!filter ? (
                    <Button className="searchTagFilter" onClick={handleOpen}>
                      <FilterAltIcon />
                    </Button>
                  ) : (
                    <Button className="searchTagFilter" onClick={resetFilter}>
                      <RotateLeftIcon />
                    </Button>
                  )}
                  <Button
                    className="excle_innbtn dowloadButton"
                    onClick={() => {
                      downloadExcel();
                    }}
                  >
                    {downloadLoader ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={20} />
                      </Box>
                    ) : (
                      <>
                        Download Tags File <FileDownloadIcon />
                      </>
                    )}
                  </Button>
                </div>
              </div>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tableRowData">
                    {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}
                    <TableCell className="tableHeadings" align="center">
                      Time
                    </TableCell>
                    <TableCell className="tableHeadings" align="center">
                      Date
                    </TableCell>
                    <TableCell className="tableHeadings" align="center">
                      First Name
                    </TableCell>
                    <TableCell className="tableHeadings" align="center">
                      Last Name
                    </TableCell>
                    <TableCell className="tableHeadings" align="center">
                      Tags
                    </TableCell>
                    {/* <TableCell align="center">
                  Action
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseTags?.map((item, index) => {
                    return (
                      <>
                        <TableRow>
                          {/* <TableCell className="tableCellData cellHeight" align="center">{index + 1}</TableCell> */}

                          <TableCell
                            className="tableCellData cellHeight"
                            align="center"
                          >
                            {moment(item?.createdAt).format("h:mm:ss a")}
                          </TableCell>

                          <TableCell
                            className="tableCellData cellHeight"
                            align="center"
                          >
                            {moment(item?.createdAt).format("Do MMM YY")}
                          </TableCell>

                          <TableCell
                            className="tableCellData cellHeight"
                            align="center"
                          >
                            {item?.userId[0]?.firstName}
                          </TableCell>
                          <TableCell
                            className="tableCellData cellHeight"
                            align="center"
                          >
                            {item?.userId[0]?.lastName}
                          </TableCell>

                          <TableCell
                            className="tableCellData cellHeight"
                            align="center"
                          >
                            {item?.searchQuery}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="paginationContainer">
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root": {
                    height: "50px",
                    width: "50px",
                    fontSize: "1rem",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#488fbf !important",
                    color: "white",
                    border: "none",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      <FormDialog
        open={fileterbool}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleClose={handleClose}
        handleFilter={handleFilter}
      />
    </div>
  );
}

export default Tags;
