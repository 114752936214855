import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import LoginPic from "../../assets/images/login.jpg";
import LogoFroth from "../../assets/images/logoFroth.png";
import {
  ForgotPasswordApi,
  ResetPasswordApi,
} from "../../shared/services/Login_api";
import { notify } from "../../shared/utils/notify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";

function ResetPassword() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleForgotPassword = () => {
    if (password.length == 0) {
      notify("Please enter password");
    } else if (password.length < 8) {
      notify("Password should be at least 8 charaters");
    } else if (password != confirmPassword) {
      notify("Passwords should match");
    } else {
      let obj = {
        password,
        confirmPassword,
      };
      setBtnLoader(true);
      let token = location?.search?.split("=")[1];
      console.log("hello", token);
      ResetPasswordApi(obj, token)
        .then(({ data }) => {
          console.log("hello", data);
          if (data) {
            if (data.status == 200) {
              notify("Password Reset!", "success");
              navigate("/");
            } else {
              notify("Something went wrong");
            }
          }
        })
        .catch((e) => {
          notify("Something went wrong");
        })
        .finally(() => {
          setBtnLoader(false);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleForgotPassword();
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${LoginPic})`,
      }}
      className="mainBackground"
    >
      <div className="cardLogin">
        <img src={LogoFroth} className="loginLogo" />

        <div style={{ position: "relative" }}>
          <input
            className={
              password.length ? "inputWithValue inputLogin" : "inputLogin"
            }
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            onKeyDown={handleKeyDown}
          />
          {showPassword ? (
            <VisibilityIcon
              className="ShowPasswordIcon"
              onClick={() => setShowPassword(!showPassword)}
              style={password.length ? { color: "#3282b8" } : {}}
            />
          ) : (
            <VisibilityOffIcon
              className="ShowPasswordIcon"
              onClick={() => setShowPassword(!showPassword)}
              style={password.length ? { color: "#3282b8" } : {}}
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <input
            className={
              confirmPassword.length
                ? "inputWithValue inputLogin"
                : "inputLogin"
            }
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            onKeyDown={handleKeyDown}
          />
          {showConfirmPassword ? (
            <VisibilityIcon
              className="ShowPasswordIcon"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={confirmPassword.length ? { color: "#3282b8" } : {}}
            />
          ) : (
            <VisibilityOffIcon
              className="ShowPasswordIcon"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={confirmPassword.length ? { color: "#3282b8" } : {}}
            />
          )}
        </div>

        <button className="loginBtn" onClick={() => handleForgotPassword()}>
          {" "}
          {btnLoader ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress className="spinnerLoader" color="inherit" />
            </Box>
          ) : (
            "Send Reset Email"
          )}
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
