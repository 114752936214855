export const getWaveForm = (waveHeight, windSpeed, swellInterval) => {
  let value = waveHeight - Number(windSpeed) / 5 + Number(swellInterval) / 7;
  Math.sign(value) == -1 ? (value = -value) : (value = value);
  return value < 2
    ? "Poor"
    : value >= 2 && value < 6
    ? "Fair"
    : value >= 6 && value < 9
    ? "Good"
    : value >= 9
    ? "Excellent"
    : "--";
};

export const generateId = () => {
  const timestamp = new Date().getTime(); // Current timestamp in milliseconds
  const randomValue = Math.floor(Math.random() * 1000000); // Random number between 0 and 999999
  return `${timestamp}-${randomValue}`; // Combine them into a single string
};
