import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import XLSX from "xlsx";
import Caution from "../../../assets/images/caution.jpg";
import Loadering from "../../../assets/images/loading.gif";
import { AllAdmin } from "../../services/UserApi";
import { ROLES } from "../../utils/constant";
import { notify } from "../../utils/notify";
import FormDialog from "../dialog";
import AddUser from "../users/AddUser";
import Addblockagain from "./Addblockagain";
import Addpopup from "./Addpopup";
import BlockUser from "./BlockUser";
import Deletepopup from "./Deletepopup";
import UserUpdate from "./UserUpdate";
import UserView from "./UserView";
import { generateId } from "../../utils/helper";
import { useDebounce } from "../../utils/useDebounce";

export default function AdminsTable(props) {
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(props.open);
  const [loader, setLoader] = useState(false);
  const [netcheckk, setNetcheckk] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [blockedyes, setBlockedyes] = useState("");
  const [change, setChange] = useState(false);
  const [pgNo, setpgNo] = useState(1);
  const [perPg, setperPg] = useState(10);
  const [searchMenu, setsearchMenu] = useState("");
  const [sortedField, setSortedField] = React.useState(null);
  const [fileterbool, setFilterBool] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fdate, setFdate] = useState("");
  const [lDate, setLdate] = useState("");
  const [delId, setdelId] = useState();
  const [blockhai, setblockhai] = useState();
  const [addId, setaddId] = useState();
  const [excelarray, setdownloadarray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [state, setState] = useStateWithCallbackLazy({
    pgNo: 1,
    perPg: 10,
    loader: true,
  });
  const closeModal = () => setFilterBool(false);
  let sortedProducts = [...dataUser];

  const downloadData = [];

  const UserViews = (resp) => {
    setLoader5(true);
    setdelId(resp);
  };
  const [updated, setUpdated] = useState(false);
  const [Totalarray, settoTalarray] = useState("");
  const updatestate = (message) => {
    setUpdated((prev) => !prev);
    notify(`${message}`, "success");
  };
  const handleblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    if (changing[findelement].isBlock == true) {
      statusblock = false;
    } else {
      statusblock = true;
    }
    changing[findelement].isBlock = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const justblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    changing[findelement].isBlock = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const newAddim = (val) => {
    val["_id"] = val.id;
    let changing = [...dataUser];
    changing.unshift(val);
    console.log("changing", changing);
    setdataUser(changing);
  };
  const handledelete = (id_delete) => {
    console.log("id_delete", id_delete);
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_delete);
    changing.splice(findelement, 1);
    setdataUser(changing);
    setBlockedyes("");
  };
  const handleupdate = (id, obj, pic) => {
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id);
    changing[findelement].firstName = obj.firstName;
    changing[findelement].lastName = obj.lastName;
    changing[findelement].phoneNumber = obj.phoneNumber;
    changing[findelement].profilePic = pic;
    setdataUser(changing);
  };
  const Userupdated = (resp) => {
    setLoader4(true);
    setaddId(resp);
  };
  const dialog = (id, blockhai) => {
    setLoader(true);
    setdelId(id);
    setblockhai(blockhai);
  };
  const addagain = (id) => {
    setLoader1(true);
    setaddId(id);
  };
  const blockUser = (id) => {
    setLoader2(true);
    setdelId(id);
  };
  const addagainblock = (id) => {
    setLoader3(true);
    setaddId(id);
  };
  const adduser = () => {
    setLoader6(true);
  };
  const netcheck = () => {
    if (!window.navigator.onLine) {
      notify("Network not found");
      setOpen(false);
      setNetcheckk(true);
    }
  };
  const emptyforce = () => {
    setdataUser([]);
    setState({
      ...state,
      pgNo: 1,
    });
    // setpgNo(1)
    setUpdated((prev) => !prev);
  };

  const {
    user: { user },
  } = useSelector((state) => state);

  const id = useRef();
  useEffect(() => {
    netcheck();

    let localId = generateId();

    id.current = localId;

    let query = `?pageNo=${currentPage}&perPage=${state.perPg}${
      searchMenu ? `&search=${searchMenu}` : ""
    }`;

    AllAdmin(query)
      .then(({ data }) => {
        if (id.current == localId) {
          setCount(data?.data?.count);
          setdataUser(data?.data?.admins);
          setState({
            ...state,
            loader: false,
          });
          if (data) {
            setOpen(false);
          }

          settoTalarray(data?.data?.count);
        }
      })
      .catch((err) => {
        if (id.current == localId) {
          setState({
            ...state,
            loader: false,
          });
        }
      });
  }, [updated, change, currentPage]);

  useDebounce(
    () => {
      emptyforce();
    },
    [searchMenu],
    500
  );

  const downloadapi = () => {
    AllAdmin()
      .then(({ data: { data } }) => {
        setdownloadarray(data.admins);

        downloadExcel(data?.admins);
        setOpen(false);
      })
      .catch((err) => {});
  };
  const filterfun = () => {
    setSortedField("");
  };
  const getApiCall = () => {
    // setpgNo(pgNo + 1);
    setState({
      ...state,
      pgNo: state.pgNo + 1,
      loader: true,
    });
    setChange((prev) => !prev);
  };
  const downloadExcel = (resp) => {
    downloadData.length = 0;
    resp.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Created_Date: moment(items.createdAt).format("MMM Do YY"),
        First_Name: items.firstName,
        Last_Name: items.lastName,
        Email: items.email,
        Active_Status: items.isDelete ? "Delete" : "Active",
        Live_Status: items.isBlock ? "Blocked" : "Live",
      };
      downloadData.push(tempObj);
    });

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Admins");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Admins Data.xlsx");
  };

  if (sortedField !== null) {
    sortedProducts.sort((a, b) => {
      if (a[sortedField] < b[sortedField]) {
        return -1;
      }
      if (a[sortedField] > b[sortedField]) {
        return 1;
      }

      return 0;
    });
  }
  const FilterDate = (firstdte, lstdte) => {
    netcheck();
    setState(
      {
        ...state,
        pgNo: 1,
      },
      (va) => {
        emptyforce();
        setFdate(firstdte);
        setLdate(lstdte);
        closeModal();
        setOpen(true);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  return (
    <div className="users_table">
      <div className="titlee">
        <h2>Admins List</h2>
      </div>
      <div className="search_view">
        <input
          type="search"
          placeholder="Search..."
          autoComplete="off"
          value={searchMenu}
          onChange={(e) => {
            setsearchMenu(e.target.value);
          }}
        ></input>
      </div>
      {open ? (
        <div className="loaadds">
          <img src={Loadering} className="loaderr" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <div className="excle_btn">
              {!fdate ? (
                ""
              ) : (
                <Button
                  className="excle_innbtn"
                  onClick={() => {
                    setState(
                      {
                        ...state,
                        pgNo: 1,
                      },
                      () => {
                        emptyforce();
                        setFdate("");
                        setLdate("");
                        setStartDate(null);
                        setEndDate(null);
                        setOpen(true);
                      }
                    );
                  }}
                >
                  <RotateLeftIcon />
                </Button>
              )}
              <Button
                className="dowloadButton"
                onClick={() => {
                  setOpen(true);
                  downloadapi();
                }}
              >
                Download Users Data <FileDownloadIcon />
              </Button>
              <Button
                className="addAdminBtn"
                variant="contained"
                color="success"
                onClick={() => {
                  adduser();
                }}
              >
                Add Admin
              </Button>
            </div>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tableRowData">
                  <TableCell className="tableHeadings" align="center">
                    First Name
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("firstName")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Last Name
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Email
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Role
                  </TableCell>
                  <TableCell
                    className="tableHeadings"
                    align="center"
                    colSpan={4}
                    style={{ width: "30%" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((dats, i) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="" align="center">
                          {dats?.firstName}{" "}
                        </TableCell>
                        <TableCell className="" align="center">
                          {dats?.lastName}
                        </TableCell>
                        <TableCell className="" align="center">
                          {dats?.email}
                        </TableCell>
                        <TableCell className="" align="center">
                          {dats?.role == ROLES.ADMIN ? "Admin" : "Super Admin"}
                        </TableCell>
                        <TableCell className="" align="center">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => {
                              UserViews(dats);
                            }}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                        {dats?.role == ROLES.ADMIN && (
                          <TableCell className="" align="center">
                            <button
                              className={
                                "bttn btn-" +
                                (dats.isDelete ? "success" : "danger")
                              }
                              type="button"
                              onClick={() => {
                                dats.isDelete
                                  ? addagain(dats._id)
                                  : dialog(dats._id, dats.isBlock);
                              }}
                            >
                              {dats.isDelete ? (
                                <AddCircleOutlineIcon />
                              ) : (
                                <DeleteForeverSharpIcon />
                              )}
                            </button>
                          </TableCell>
                        )}
                        <TableCell className="" align="center">
                          <button
                            onClick={() => {
                              Userupdated(dats);
                            }}
                            className="bttn btn btnupdate"
                            type="button"
                          >
                            <EditSharpIcon />
                          </button>
                        </TableCell>
                        {dats?.role == ROLES.ADMIN && (
                          <TableCell className="" align="left">
                            <div
                              className={dats.isDelete ? "deactive" : "active"}
                            >
                              <label>
                                {dats.isBlock || dats.isDelete
                                  ? "Blocked"
                                  : "Live"}
                              </label>
                              <Switch
                                onClick={() => {
                                  dats.isBlock
                                    ? addagainblock(dats._id)
                                    : blockUser(dats._id);
                                }}
                                checked={!dats.isBlock && !dats.isDelete}
                                color={dats.isBlock ? "warning" : "success"}
                              />
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>

            {loader ? (
              <Deletepopup
                id={delId}
                delete={handledelete}
                updstate={updatestate}
                close={setLoader}
                title="Admins"
              />
            ) : (
              ""
            )}
            {loader1 ? (
              <Addpopup
                id={addId}
                updstate={updatestate}
                adddelete={handledelete}
                close={setLoader1}
                title="Admins"
              />
            ) : (
              ""
            )}
            {loader2 ? (
              <BlockUser
                id={delId}
                updstate={updatestate}
                blocked={handleblocked}
                close={setLoader2}
                title="Admins"
              />
            ) : (
              ""
            )}
            {loader3 ? (
              <Addblockagain
                id={addId}
                updstate={updatestate}
                unblocked={handleblocked}
                close={setLoader3}
                title="Admins"
              />
            ) : (
              ""
            )}
            {loader4 ? (
              <UserUpdate
                id={addId}
                updstate={updatestate}
                update={handleupdate}
                close={setLoader4}
                title="Admin"
              />
            ) : (
              ""
            )}
            {loader5 ? (
              <UserView resp={delId} close={setLoader5} title="Admins" />
            ) : (
              ""
            )}
            {loader6 ? (
              <AddUser
                updstate={updatestate}
                close={setLoader6}
                newAddAdmin={newAddim}
                title="Admins"
              />
            ) : (
              ""
            )}
          </TableContainer>
          <div className="paginationContainer">
            <Pagination
              count={Math.ceil(count / 10)}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  height: "50px",
                  width: "50px",
                  fontSize: "1rem",
                },
                "& .Mui-selected": {
                  backgroundColor: "#488fbf !important",
                  color: "white",
                  border: "none",
                },
              }}
            />
          </div>
        </>
      )}
      {netcheckk ? (
        <div className="loaderr">
          <img src={Caution}></img>
          <h3>Your connection is not available</h3>
        </div>
      ) : (
        ""
      )}
      <FormDialog
        open={fileterbool}
        handleClose={closeModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        callback={FilterDate}
      />
    </div>
  );
}
