import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_reportapi,
  Endpoint_GetreportedUser,
  Endpoint_reported_chat,
  Endpoint_Edit_Msg,
  Endpoint_Delete_Msg,
  Endpoint_reported_profiles,
  Endpoint_createReport,
  ReviewBeachReport,
  Endpoint_Admin_Download,
} from "../utils/endpoint";
const ReportapiData = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint_reportapi.reportApi + query);
  } else {
    return HTTP_CLIENT.get(Endpoint_reportapi.reportApi);
  }
};
const GetreportedUser = () => {
  return HTTP_CLIENT.get(Endpoint_GetreportedUser.GetreportedUser);
};
const GetreportedChat = (currentPage, searchMenu) => {
  return HTTP_CLIENT.get(
    Endpoint_reported_chat.reported_chat +
      `?pageNo=${currentPage}&perPage=10${
        searchMenu ? `&search=${searchMenu}` : ""
      }`
  );
};

const EditMsgServices = (obj) => {
  return HTTP_CLIENT.put(Endpoint_Edit_Msg.editMessage, obj);
};

const deleteMsgServices = (id) => {
  return HTTP_CLIENT.delete(Endpoint_Delete_Msg.deleteMsg + id);
};

const reportUserAPI = (currentPage, searchMenu) => {
  return HTTP_CLIENT.get(
    Endpoint_reported_profiles.reportUser +
      `?pageNo=${currentPage}&perPage=10${
        searchMenu ? `&search=${searchMenu}` : ""
      }`
  );
};

const CreateReportAPI = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key, inx) => {
    if (key == "media") {
      obj[key].forEach((keyobj, sinx) => {
        formBody.append(`media[${sinx}]`, keyobj);
      });
    } else {
      formBody.append(key, obj[key]);
    }
  });
  return HTTP_CLIENT.post(Endpoint_createReport.createReport, formBody);
};

const addReviewBeach = (obj) => {
  return HTTP_CLIENT.post(ReviewBeachReport?.addReview, obj);
};

//getReviewProduct

const getReportReview = (id) => {
  return HTTP_CLIENT.get(ReviewBeachReport?.getReview + id);
};

const deleteAllSelectedReports = (obj) => {
  return HTTP_CLIENT.put(ReviewBeachReport?.deleteSelectedReported, obj);
};

const GetAllReports = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint_Admin_Download.reports + query);
  } else return HTTP_CLIENT.get(Endpoint_Admin_Download.reports);
};

export {
  ReportapiData,
  GetreportedUser,
  GetreportedChat,
  EditMsgServices,
  deleteMsgServices,
  reportUserAPI,
  CreateReportAPI,
  addReviewBeach,
  getReportReview,
  deleteAllSelectedReports,
  GetAllReports,
};
