import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import ImgPlaceholder from "../../../assets/images/ChatPlaceholder1.svg";
import ImgPlaceholder2 from "../../../assets/images/ChatPlaceholder2.svg";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import Loadering from "../../../assets/images/loading.gif";
import { Userapi_bloc_ConversatiobkData } from "../../services/User_api_block";
import { Userapi_unblockCoversationData } from "../../services/User_api_unblock";
import {
  Get_all_conversations,
  Get_messages,
} from "../../services/conversation";
import { notify } from "../../utils/notify";
import ReportChat from "../../utils/reportChat";

// For upadting/deleting chat
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { EditMsgServices, deleteMsgServices } from "../../services/ReportApi";
import { useDebounce } from "../../utils/useDebounce";

function Chat() {
  const [conversationsList, setConversationsList] = useState([]);
  const [messagesCon, setmessages] = useState([]);
  const [actives, setactives] = useState("");
  const [bar, setbar] = useState("");
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [loader, setLoader] = useState(false);
  const [dataReport, setdataReport] = useState("");
  const [updated, setUpdated] = useState(false);
  const [productId, setProductId] = useState({});

  // For upadtig/deleting chat
  const [openModal, setOpenModal] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState({});
  const [msgSide, setMsgSide] = useState({});
  const [updatedMsg, setUpdatedMsg] = useState("");
  const [updateBool, setUpdateBool] = useState("");
  const [checkingChat, setCheckingChat] = useState("");
  const [blockedId, setBlockedId] = useState({});
  const [searchMenu, setsearchMenu] = useState("");

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const updatestate = (message) => {
    setUpdated((prev) => !prev);
    notify(`${message}`, "success");
  };

  const getAllConversations = () => {
    Get_all_conversations()
      .then(({ data: { data } }) => {
        setConversationsList(data);
        setbar(data[0]?.members);
        setProductId(data[0]?.productId);
        setactives(data[0]?._id);
        setCheckingChat(data ? data[0] : {});

        setOpen(false);
        // messages(data[0]?._id);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllConversations();
  }, [updated]);
  useEffect(() => {
    if (actives) {
      messages(actives);
    }
  }, [actives]);
  const messages = (id) => {
    setOpen2(true);
    Get_messages(id)
      .then(({ data: { data } }) => {
        setmessages(data);
        setOpen2(false);
      })
      .catch((err) => {});
  };
  const activeChat = (item) => {
    setactives(item?._id);
    setbar(item?.members);
    setCheckingChat(item);
    if (item?.block_status.receiver.isBlocked) {
      setBlockedId(item?.block_status.receiver.id);
    } else if (item?.block_status.sender.isBlocked) {
      setBlockedId(item?.block_status.sender.id);
    }
    setProductId(item?.productId);
  };
  const chatReporty = () => {
    setLoader(true);
    setdataReport(bar);
  };

  const dateBack = (data) => {
    let result = "";
    let latest = moment(data).format("YYYY-MM-DD");
    let newwwws = moment().format("YYYY-MM-DD");
    if (latest == newwwws) {
      result = moment(data).fromNow();
    } else {
      result = moment(data).format("DD/MM/YYYY - hh:mm A");
    }
    return result;
  };

  const handlingSelectiveMsg = (item, str) => {
    setSelectedMsg(item);
    setMsgSide(str);
    setUpdatedMsg(item?.text);
    setUpdateBool(item?.text);
  };

  const handleBlock = (conversationId, memberId) => {
    let obj = {
      conversationId: conversationId,
      blockUserId: memberId,
    };
    setOpen(true);
    setOpen2(true);
    Userapi_bloc_ConversatiobkData(obj)
      .then(({ data }) => {
        if (data) {
          getAllConversations();
          setOpen(false);
          setOpen2(false);
        }
      })
      .catch((err) => {});
  };

  const handleUnBlock = (conversationId, memberId) => {
    let obj = {
      conversationId: conversationId,
      blockUserId: memberId,
    };
    setOpen(true);
    setOpen2(true);
    Userapi_unblockCoversationData(obj)
      .then(({ data }) => {
        if (data) {
          getAllConversations();
          setOpen(false);
          setOpen2(false);
        }
      })
      .catch((err) => {});
  };

  useDebounce(
    () => {
      getAllConversations();
    },
    [searchMenu],
    500
  );
  return (
    <div>
      <div className="mainChat">
        <div className="sideChat">
          <div className="mainBox">
            <h2 style={{ marginBottom: "10%", marginTop: "3%" }}>Chats</h2>
            <div className="search_view" style={{ position: "relative" }}>
              <input
                type="search"
                placeholder="Search..."
                value={searchMenu}
                autoComplete="off"
                onChange={(e) => {
                  setsearchMenu(e.target.value);
                  // emptyforce();
                }}
              />
              <SearchIcon className="searchIcon" />
            </div>
          </div>
          {open ? (
            <div className="center spinss">
              <img src={Loadering} className="loaderr " />{" "}
            </div>
          ) : (
            <ul>
              {conversationsList
                ?.filter((ii) =>
                  searchMenu !== ""
                    ? ii.members[0]?.firstName
                        .toLocaleLowerCase()
                        .includes(searchMenu.toLocaleLowerCase())
                    : ii.members[1]?.firstName
                    ? ii.members[1]?.firstName
                        .toLocaleLowerCase()
                        .includes(searchMenu.toLocaleLowerCase())
                    : ii
                )
                .map((item) => {
                  return (
                    <>
                      <li
                        className={
                          actives == item?._id ? "activeSiide" : "nonActive"
                        }
                        onClick={() => activeChat(item)}
                      >
                        <div className="flexTwoWay">
                          <div className="inerflex ">
                            {item?.members[0]?.profilePic ? (
                              <img src={item?.members[0]?.profilePic}></img>
                            ) : (
                              <img src={ImgPlaceholder} />
                            )}
                            {item?.members[1]?.profilePic ? (
                              <img
                                className="imgChat"
                                src={item?.members[1]?.profilePic}
                              ></img>
                            ) : (
                              <img className="imgChat" src={ImgPlaceholder2} />
                            )}
                          </div>
                          <div className="NameHour">
                            <div className="msgNames">
                              <span
                                style={{ fontSize: "14px", marginLeft: "16px" }}
                              >
                                <span
                                  style={
                                    (item.block_status.sender.isBlocked &&
                                      item?.members[0]._id ==
                                        item.block_status.sender.id) ||
                                    (item.block_status.receiver.isBlocked &&
                                      item?.members[0]._id ==
                                        item.block_status.receiver.id)
                                      ? { opacity: "0.5" }
                                      : {}
                                  }
                                >
                                  {item?.members[0]?.firstName}{" "}
                                  {item?.members[0]?.lastName}
                                </span>{" "}
                                &<br></br>
                                <span
                                  style={
                                    (item.block_status.sender.isBlocked &&
                                      item?.members[1]._id ==
                                        item.block_status.sender.id) ||
                                    (item.block_status.receiver.isBlocked &&
                                      item?.members[1]._id ==
                                        item.block_status.receiver.id)
                                      ? { opacity: "0.5" }
                                      : {}
                                  }
                                >
                                  {item?.members[1]?.firstName}{" "}
                                  {item?.members[1]?.lastName}
                                </span>
                              </span>
                              <span></span>
                            </div>
                            {/* <div className="timeHour">
                          <span>
                          1h ago
                          {moment(item?.members.updatedAt).format("h:mm A")}
                          </span>
                        </div> */}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ul>
          )}
        </div>
        <div className="conversation">
          {open2 ? (
            <div className="center spins">
              <img src={Loadering} className="loaderr " />{" "}
            </div>
          ) : (
            <div className="topBar">
              <div className="leftbar">
                {/* from here */}
                <div
                  className="flexTwoWay toospp"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "92%",
                    margin: "0 auto",
                  }}
                >
                  <div className="inerflex bari chatFlex">
                    {bar[0]?.profilePic ? (
                      <img className="chatImg" src={bar[0]?.profilePic}></img>
                    ) : (
                      <img className="chatImg" src={ImgPlaceholder}></img>
                    )}
                    <span style={{ whiteSpace: "nowrap" }}>
                      {bar[0]?.firstName} {bar[0]?.lastName}
                    </span>
                    {(checkingChat?.block_status?.sender?.isBlocked &&
                      bar[0]?._id == checkingChat?.block_status?.sender.id) ||
                    (checkingChat?.block_status?.receiver?.isBlocked &&
                      bar[0]?._id == checkingChat.block_status.receiver.id) ? (
                      <button
                        className="unblockButton"
                        onClick={() =>
                          handleUnBlock(checkingChat?._id, bar[0]._id)
                        }
                      >
                        Unblock
                      </button>
                    ) : (
                      <button
                        className="blockButton"
                        onClick={() =>
                          handleBlock(checkingChat?._id, bar[0]._id)
                        }
                      >
                        Block
                      </button>
                    )}
                  </div>

                  <div className="inerflex bari chatFlex">
                    {bar[1]?.profilePic ? (
                      <img className="chatImg" src={bar[1]?.profilePic}></img>
                    ) : (
                      <img className="chatImg" src={ImgPlaceholder2}></img>
                    )}
                    <span style={{ whiteSpace: "nowrap" }}>
                      {bar[1]?.firstName} {bar[1]?.lastName}
                    </span>
                    {(checkingChat?.block_status?.sender?.isBlocked &&
                      bar[1]?._id == checkingChat?.block_status?.sender.id) ||
                    (checkingChat?.block_status?.receiver?.isBlocked &&
                      bar[1]?._id == checkingChat.block_status.receiver.id) ? (
                      <button
                        className="unblockButton"
                        onClick={() =>
                          handleUnBlock(checkingChat?._id, bar[1]._id)
                        }
                      >
                        Unblock
                      </button>
                    ) : (
                      <button
                        className="blockButton"
                        onClick={() =>
                          handleBlock(checkingChat?._id, bar[1]._id)
                        }
                      >
                        Block
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="rightbar">
              {!open ? (
                <span onClick={() => chatReporty()}>
                  <button className="blockButton">
                    Block User
                  </button>
                </span>
              ) : (
                ""
              )}
            </div> */}
            </div>
          )}
          {open2 ? (
            <div className="center spins">
              <img src={Loadering} className="loaderr " />{" "}
            </div>
          ) : (
            <div className="inboxChat">
              <div className="productCard">
                <div>
                  <img
                    src={productId?.images[0]}
                    style={{
                      height: "145px",
                      width: "245px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "4%" }} className="productCardText">
                  <Typography gutterBottom variant="h5" component="div">
                    {productId?.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {productId?.description}
                  </Typography>
                </div>
              </div>
              {/* <Card sx={{ maxWidth: 345 }} style={{
                marginTop: "20px",
                marginBottom: "20px"
              }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={productId?.images[0]}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {productId?.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {productId?.description}
                  </Typography>
                </CardContent>
              </Card> */}

              {messagesCon?.map((items, inx) => {
                return (
                  <>
                    {messagesCon.length > 0 && inx == 0 ? (
                      <div className="lineDiv">
                        <div className="line"></div>
                        <div className="lineText">
                          {moment(messagesCon[inx].createdAt).format(
                            "Do MMM, YYYY"
                          )}
                        </div>
                        <div className="line"></div>
                      </div>
                    ) : moment(messagesCon[inx].createdAt).format(
                        "Do MMM, YYYY"
                      ) !==
                      moment(messagesCon[inx - 1].createdAt).format(
                        "Do MMM, YYYY"
                      ) ? (
                      <div className="lineDiv">
                        <div className="line"></div>
                        <div className="lineText">
                          {moment(messagesCon[inx].createdAt).format(
                            "Do MMM, YYYY"
                          )}
                        </div>
                        <div className="line"></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {items?.senderId?._id == bar[0]?._id ? (
                      <div className="leftChat">
                        <div className="chatimg">
                          {items?.senderId?.profilePic ? (
                            <div style={{ position: "relative" }}>
                              <ProgressiveImage
                                src={bar[0]?.profilePic}
                                placeholder={Loaadings}
                              >
                                {(src, loading) => (
                                  <img
                                    className={`image${
                                      loading ? " loading" : " loaded"
                                    }`}
                                    src={src}
                                    alt={Avatar}
                                  />
                                )}
                              </ProgressiveImage>
                            </div>
                          ) : (
                            <img
                              className="image loaded"
                              src={ImgPlaceholder}
                            />
                          )}
                        </div>
                        <div className="mainfl">
                          <div className="leftNamee">
                            <h2>{items?.senderId?.firstName}</h2>
                            <p className="">
                              {/* {items?.senderId?.firstName + " " + items?.senderId?.lastName}, &nbsp; */}
                              {moment(items?.createdAt).format("h:mm A")}
                            </p>
                          </div>
                          <div className="editflex">
                            <div
                              className="chatcontent"
                              onClick={() => {
                                setOpenModal(true);
                                handlingSelectiveMsg(items, "left");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {items?.image ? (
                                <>
                                  <ProgressiveImage
                                    src={items?.image}
                                    placeholder={Loaadings}
                                  >
                                    {(src, loading) => (
                                      <img
                                        className={`image${
                                          loading ? " loading" : " loaded"
                                        }`}
                                        src={src}
                                        alt={Avatar}
                                      />
                                    )}
                                  </ProgressiveImage>
                                </>
                              ) : (
                                <>
                                  <p>{items?.text}</p>
                                </>
                              )}
                            </div>
                            <ModeEditOutlineIcon
                              onClick={() => {
                                setOpenModal(true);
                                handlingSelectiveMsg(items, "left");
                              }}
                              style={{ cursor: "pointer", height: "15px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="leftChat rightt">
                        <div className="mainfl">
                          <div className="leftNamee">
                            <h2>{items?.senderId?.firstName}</h2>
                            <p className="">
                              {/* {items?.senderId?.firstName + " " + items?.senderId?.lastName}, &nbsp; */}
                              {moment(items?.createdAt).format("h:mm A")}
                            </p>
                          </div>
                          <div className="editflex">
                            <ModeEditOutlineIcon
                              onClick={() => {
                                setOpenModal(true);
                                handlingSelectiveMsg(items, "right");
                              }}
                              style={{ cursor: "pointer", height: "15px" }}
                            />
                            <div
                              className="chatcontent rightSideChat"
                              onClick={() => {
                                setOpenModal(true);
                                handlingSelectiveMsg(items, "right");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {items?.image ? (
                                <>
                                  <ProgressiveImage
                                    src={items?.image}
                                    placeholder={Loaadings}
                                  >
                                    {(src, loading) => (
                                      <img
                                        className={`image${
                                          loading ? " loading" : " loaded"
                                        }`}
                                        src={src}
                                        alt={Avatar}
                                      />
                                    )}
                                  </ProgressiveImage>
                                </>
                              ) : (
                                <>
                                  <p>{items?.text}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="chatimg rightImg">
                          {items?.senderId?.profilePic ? (
                            // <img src={bar[1]?.profilePic}></img>
                            <ProgressiveImage
                              src={bar[1]?.profilePic}
                              placeholder={Loaadings}
                            >
                              {(src, loading) => (
                                <img
                                  className={`image${
                                    loading ? " loading" : " loaded"
                                  }`}
                                  src={src}
                                  alt={Avatar}
                                />
                              )}
                            </ProgressiveImage>
                          ) : (
                            <img
                              className="image loaded"
                              src={ImgPlaceholder2}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>
        {loader ? (
          <ReportChat
            data={dataReport}
            close={setLoader}
            updstate={updatestate}
            checkingChat={checkingChat}
          />
        ) : (
          ""
        )}
      </div>
      <Popup
        open={openModal}
        setOpen={setOpenModal}
        handleClose={handleClose}
        MsgObj={selectedMsg}
        msgSide={msgSide}
        updatedMsg={updatedMsg}
        setUpdatedMsg={setUpdatedMsg}
        updateBool={updateBool}
        setLoader={setOpen2}
        messages={messages}
      />
    </div>
  );
}

export default Chat;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Popup({
  open,
  setOpen,
  handleClose,
  MsgObj,
  updatedMsg,
  setUpdatedMsg,
  updateBool,
  setLoader,
  messages,
}) {
  const [editSpinner, setEditSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState(false);

  const handleDelete = () => {
    setDeleteSpinner(true);
    deleteMsgServices(MsgObj._id)
      .then((data) => {
        if (data) {
          messages(MsgObj.conversationId);
          handleClose();
          setDeleteSpinner(false);
        }
      })
      .catch(() => {});
  };

  const handleEdit = () => {
    if (!(updatedMsg == updateBool)) {
      let obj = {
        conversationId: MsgObj.conversationId,
        messageId: MsgObj._id,
        text: updatedMsg,
      };
      setEditSpinner(true);
      setLoader(true);
      EditMsgServices(obj)
        .then((data) => {
          if (data.data) {
            messages(MsgObj.conversationId);
            handleClose();
            setEditSpinner(false);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "10% 10% 5% 10%" }}>
          {!MsgObj?.image ? (
            <>
              <textarea
                className="editMsg"
                value={updatedMsg}
                onChange={(e) => setUpdatedMsg(e.target.value)}
              />
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => handleEdit()}
                  variant="contained"
                  style={{
                    background: "#77C606",
                    marginLeft: "0px",
                    width: "46%",
                    height: "50px",
                  }}
                >
                  {editSpinner ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : updateBool == updatedMsg ? (
                    "Edit"
                  ) : (
                    "Update"
                  )}
                </Button>
                <Button
                  onClick={() => handleDelete()}
                  variant="contained"
                  style={{
                    background: "#C63206",
                    width: "46%",
                    height: "50px",
                  }}
                >
                  {deleteSpinner ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={MsgObj?.image}
                style={{
                  height: "200px",
                  width: "150px",
                  margin: "0 auto",
                  objectFit: "cover",
                }}
              />
              <Button
                onClick={() => handleDelete()}
                variant="contained"
                className="buttonMsgdelete"
                style={{
                  background: "#C63206",
                  width: "150px",
                  marginTop: "5%",
                  margin: "0 auto",
                  height: "50px",
                }}
              >
                {deleteSpinner ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
