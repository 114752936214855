const Endpoint_LoginApi = {
  LoginApi: "auth/login-admin/",
  LogoutApi: "auth/logout-user/",
  ForgotPassword: "auth/admin-forget-password",
  ResetPassword: "auth/reset-admin-password",
};
const Endpoint_AllAdmin = {
  AllAdmin: "admin/get-all-admins/",
};
const Endpoint_registrationApi = {
  registrationApi: "auth/register/",
};
const Endpoint_AdminregistrationApi = {
  AdminregistrationApi: "admin/create-admin/",
};
const Endpoint = {
  updateUser: "user/update-profile/",
};
const EndpointAdmin = {
  updateAdmin: "admin/update-admin/",
};
const Endpoint_Product = {
  updateProduct: "admin/update-product/",
};
const Endpoint_Report = {
  updateReport: "admin/update-report/",
};
const Endpoint_Userapi = {
  userApi: "admin/get-users/",
};
const Endpoint_reportapi = {
  reportApi: "admin/get-reports/",
};
const Endpoint_Productapi = {
  productApi: "admin/get-products/",
};
const Endpoint_Productapi_block = {
  product_blockApi: "admin/verify-product/",
};
const Endpoint_Productapi_unblock = {
  product_unblockApi: "admin/unverify-product/",
};
const Endpoint_Reportapi_block = {
  Report_blockApi: "admin/block-report/",
};
const Endpoint_Reportapi_unblock = {
  Report_unblockApi: "admin/unblock-report/",
};
const Endpoint_userApi_Block = {
  userApi_Block: "admin/block-user/",
};
const Endpoint_userApi_Conversation_Block = {
  userApi_Block: "admin/block-user-from-conversation/",
};
const Endpoint_AdminApi_Block = {
  AdminApi_Block: "admin/block-admin/",
};
const Endpoint_AdminApi_unBlock = {
  AdminApi_unBlock: "admin/unblock-admin/",
};
const Endpoint_product_review = {
  product_review: "admin/add-product-review",
};
const Endpoint_report_review = {
  report_review: "admin/add-report-review",
};
const Endpoint_review_by_product = {
  review_by_product: "admin/get-review-by-product/",
};
const Endpoint_review_by_report = {
  review_by_report: "admin/get-review-by-report/",
};
const Endpoint_get_all_beaches = {
  get_all_beaches: "admin/get-all-beaches/",
};
const Endpoint_get_all_conversations = {
  get_all_conversations: "admin/get-all-conversations/",
};
const Endpoint_get_messages = {
  get_messages: "admin/get-messages/",
};

const Endpoint_Admin_delete = {
  Admin_delete: "admin/delete-admin/",
};
const Endpoint_userApi_UnBlock = {
  userApi_unBlock: "admin/unblock-user/",
  userApi_Conversation_unBlock: "admin/unBlock-user-from-conversation",
};
const Endpoint_userApi_delete = {
  userApi_delete: "admin/delete-user/",
};
const Endpoint_reported_chat = {
  reported_chat: "admin/get-reported-chats/",
};
const Endpoint_userApi_Add = {
  userApi_Add: "admin/recover-user/",
};
const Endpoint_searchTagsAll = {
  searchTagsAll: "admin/get-all-searches/",
};
const Endpoint_SearchTagsAllById = {
  SearchTagsAllById: "admin/get-searches/",
};
const Endpoint_GetreportedUser = {
  GetreportedUser: "admin/get-reported-users/",
};

const Endpoint_DeleteProduct = {
  deleteProduct: "admin/block-product/",
};

const Endpoint_Edit_Msg = {
  editMessage: "admin/edit-chat-message",
};

const Endpoint_Delete_Msg = {
  deleteMsg: "admin/delete-message/",
};

const Endpoint_reported_profiles = {
  reportUser: "admin/get-reported-profiles/",
};

const Endpoint_createReport = {
  createReport: "admin/add-report",
};

const Endpoint_add_product = {
  addProduct: "admin/add-product",
};

const Testimonial = {
  addTestimonial: "testimonial/add-testimonial",
  updateTestimonial: "testimonial/edit-testimonial/",
  deleteTestimonial: "testimonial/delete-testimonial/",
  getTestimonial: "testimonial/get-testimonials",
};

const ReviewAdminProduct = {
  reviewAdmin: "admin/add-product-review",
  getReviewProduct: "admin/get-review-by-product/",
};

const ReviewBeachReport = {
  addReview: "admin/add-report-review",
  getReview: "admin/get-review-by-report/",
  deleteSelectedReported: "admin/block-report",
};

const FavouriteBeaches = {
  getFavBeaches: "admin/get-user-fav-beaches",
};

const EditProfile = {
  editProfileInfo: "/admin/edit-super-admin",
  changePassword: "admin/update-super-admin-password",
};

const Endpoint_Admin_Download = {
  reports: "admin/get-reports-for-download",
  products: "admin/get-products-for-download",
  searches: "admin/get-searches-for-download",
};

const GROUP_ENDPOINTTS = {
  getGroups: "admin/get-all-groups",
  delete: "admin/delete-group/",
  getAllUsers: "admin/get-users-list",
  addGroup: "admin/add-group",
  addMessage: "admin/add-group-message",
  editGroup: "admin/update-group/",
  groupChat: "group/single-group-data/",
  groupMsgs: "admin/get-group-messages/",
  groupRequests: "request/get-requests",
  acceptRequest: "request/accept-request",
  rejectRequest: "request/reject-request",
};

const WEATHER_ENDPOINTS = {
  getData: "weather/",
  getCurrentInfo: "weather/current/",
};

const DASHBOARD_ENDPOINTS = {
  getCounts: "admin/get-counts",
};
export {
  Endpoint,
  Endpoint_Product,
  Endpoint_Report,
  Endpoint_Userapi,
  Endpoint_Productapi,
  Endpoint_reportapi,
  Endpoint_Productapi_block,
  Endpoint_Productapi_unblock,
  Endpoint_Reportapi_block,
  Endpoint_Reportapi_unblock,
  Endpoint_LoginApi,
  Endpoint_registrationApi,
  Endpoint_userApi_Block,
  Endpoint_userApi_UnBlock,
  Endpoint_userApi_delete,
  Endpoint_userApi_Add,
  Endpoint_searchTagsAll,
  Endpoint_SearchTagsAllById,
  Endpoint_GetreportedUser,
  Endpoint_AdminregistrationApi,
  Endpoint_AllAdmin,
  Endpoint_Admin_delete,
  EndpointAdmin,
  Endpoint_AdminApi_unBlock,
  Endpoint_AdminApi_Block,
  Endpoint_product_review,
  Endpoint_review_by_product,
  Endpoint_review_by_report,
  Endpoint_report_review,
  Endpoint_get_all_beaches,
  Endpoint_get_all_conversations,
  Endpoint_get_messages,
  Endpoint_reported_chat,
  Endpoint_DeleteProduct,
  Endpoint_Edit_Msg,
  Endpoint_Delete_Msg,
  Endpoint_reported_profiles,
  Endpoint_createReport,
  Endpoint_add_product,
  Testimonial,
  ReviewAdminProduct,
  ReviewBeachReport,
  FavouriteBeaches,
  EditProfile,
  Endpoint_userApi_Conversation_Block,
  Endpoint_Admin_Download,
  GROUP_ENDPOINTTS,
  WEATHER_ENDPOINTS,
  DASHBOARD_ENDPOINTS,
};
