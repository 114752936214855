import { Button } from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import XLSX from "xlsx";
import Loadering from "../../../assets/images/loading.gif";
import { GetreportedChat, reportUserAPI } from "../../services/ReportApi";
import UserView from "../users/UserView";
import { useDebounce } from "../../utils/useDebounce";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { generateId } from "../../utils/helper";

function ReportFeedBack(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(props.open);
  const [User, setUser] = useState([]);
  const [searchMenu, setsearchMenu] = useState("");
  const [updated, setUpdated] = useState(false);
  const [SelectUsers, setSelectUser] = useState();
  const [catID, setcATid] = useState("");
  const [state, setState] = useStateWithCallbackLazy({
    pgNo: 1,
    perPg: 10,
    loader: true,
  });

  const [responseTags, setresponseTags] = useState([]);
  const [loader5, setLoader5] = useState(false);
  const [delId, setdelId] = useState();

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  const emptyforce = () => {
    setresponseTags([]);
    setState({
      ...state,
      pgNo: 1,
    });
    setUpdated((prev) => !prev);
  };

  const getReportedProfiles = (localId) => {
    setOpen(true);
    reportUserAPI(currentPage, searchMenu)
      .then((data) => {
        if (id.current == localId) {
          setOpen(false);
          setresponseTags(data.data.data?.reports);
          setCount(data?.data?.data?.count);
        }
      })
      .catch((err) => {});
  };

  const downloadData = [];

  const id = useRef();
  useEffect(() => {
    if (props.title != "Reported Chats") {
      let localId = generateId();
      id.current = localId;

      searchtgsa(localId);
    } else {
      let localId = generateId();
      id.current = localId;
      reportedUser(localId);
    }
  }, [updated, props.title, currentPage]);

  useEffect(() => {
    setsearchMenu("");
  }, [props.title]);

  const UserViews = (resp) => {
    setLoader5(true);
    setdelId(resp);
  };

  const searchtgsa = (localId) => {
    setresponseTags([]);
    console.log("ok", localId);
    getReportedProfiles(localId);
  };
  const reportedUser = (localId) => {
    setresponseTags([]);
    // GetreportedUser([]);
    setOpen(true);
    GetreportedChat(currentPage, searchMenu)
      .then(({ data }) => {
        if (id.current == localId) {
          if (data) {
            setCount(data?.data?.count);
            setresponseTags(data.data?.reports);
          }
          setOpen(false);
        }
      })
      .catch((err) => {});
  };

  const downloadExcel = () => {
    downloadData.length = 0;
    responseTags?.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Time: moment(items.createdAt).format("h:mm:ss a"),
        Date: moment(items.createdAt).format("Do MMM YY"),
        ReportTo: items?.reportedUserId?.firstName,
        BeachName: items?.beachId?.name,
        ReportBy: items?.userId?.firstName,
        Reason: items?.reason,
      };
      downloadData.push(tempObj);
    });
    setOpen(false);

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Complain");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Complain.xlsx");
  };
  const downloadExcelRePORT = () => {
    downloadData.length = 0;
    responseTags?.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Time: moment(items.createdAt).format("h:mm:ss a"),
        Date: moment(items.createdAt).format("Do MMM YY"),
        ReportTo: items?.reportedUserId?.firstName,
        ReportBy: items?.userId?.firstName,
        Reason: items?.reason,
      };
      downloadData.push(tempObj);
    });
    setOpen(false);

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Complain");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Complain.xlsx");
  };
  useDebounce(
    () => {
      emptyforce();
    },
    [searchMenu],
    500
  );
  return (
    <div>
      <div className="users_table">
        <div className="titlee">
          <h2>{props.title}</h2>
        </div>
        <div className="search_view">
          <input
            type="search"
            placeholder="Search..."
            autoComplete="off"
            value={searchMenu}
            onChange={(e) => {
              setsearchMenu(e.target.value);
            }}
          ></input>
        </div>
        {open ? (
          <div className="loaadds">
            <img src={Loadering} className="loaderr" />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <div className="excle_btn">
                <Button
                  id="dwnlProfiles"
                  className="excle_innbtn dowloadButton"
                  onClick={() => {
                    props.title != "Reported Chats"
                      ? downloadExcel()
                      : downloadExcelRePORT();
                    // setOpen(true);
                  }}
                >
                  {props.title != "Reported Chats"
                    ? "Download Reported Profiles"
                    : "Download Reported Chats"}{" "}
                  <FileDownloadIcon />
                </Button>
              </div>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tableRowData">
                    {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}
                    <TableCell className="tableHeadings" align="center">
                      Report By
                    </TableCell>
                    <TableCell className="tableHeadings" align="center">
                      Report To
                    </TableCell>

                    <TableCell className="tableHeadings" align="center">
                      Reason
                    </TableCell>
                    <TableCell
                      className="tableHeadings"
                      align="center"
                      colSpan={4}
                    >
                      Action
                    </TableCell>

                    {/* <TableCell align="center">
                  Action
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseTags?.reverse().map((item, index) => {
                    return (
                      <>
                        <TableRow>
                          {/* <TableCell className="tableCellData" align="center">{index + 1}</TableCell> */}
                          <TableCell className="tableCellData" align="center">
                            {item?.userId?.firstName}
                          </TableCell>
                          <TableCell className="tableCellData" align="center">
                            {item?.reportedUserId?.firstName}
                          </TableCell>

                          <TableCell className="tableCellData" align="center">
                            {item?.reason}
                          </TableCell>
                          <TableCell className="tableCellData" align="center">
                            <button
                              className="bttn btn-success"
                              type="button"
                              onClick={() => {
                                UserViews(item);
                              }}
                            >
                              <VisibilityIcon />
                            </button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {loader5 ? (
                <UserView resp={delId} close={setLoader5} feedss />
              ) : (
                ""
              )}
            </TableContainer>
            <div className="paginationContainer">
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root": {
                    height: "50px",
                    width: "50px",
                    fontSize: "1rem",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#488fbf !important",
                    color: "white",
                    border: "none",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReportFeedBack;
