import { Endpoint_LoginApi } from "../utils/endpoint";
import { HTTP_CLIENT } from "../utils/interceptor";

const LoginApi = async (obj) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(obj);
  return HTTP_CLIENT.post(Endpoint_LoginApi.LoginApi, body, config);
};

const ForgotPasswordApi = async (obj) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(obj);
  return HTTP_CLIENT.post(Endpoint_LoginApi.ForgotPassword, body, config);
};

const ResetPasswordApi = async (obj, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = JSON.stringify(obj);
  return HTTP_CLIENT.post(Endpoint_LoginApi.ResetPassword, body, config);
};

const handleLogOut = () => {
  return HTTP_CLIENT.put(`${Endpoint_LoginApi.LogoutApi}`);
};
export { ForgotPasswordApi, handleLogOut, ResetPasswordApi, LoginApi };
