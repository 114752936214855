import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import LoginPic from "../../assets/images/login.jpg";
import LogoFroth from "../../assets/images/logoFroth.png";
import { ForgotPasswordApi } from "../../shared/services/Login_api";
import { notify } from "../../shared/utils/notify";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const handleForgotPassword = () => {
    if (email.length == 0) {
      notify("Please enter email");
    } else {
      let obj = {
        email: email,
      };
      setBtnLoader(true);
      ForgotPasswordApi(obj)
        .then(({ data }) => {
          console.log("hello", data);
          if (data) {
            if (data.status == 200) {
              notify("Reset password email sent!", "success");
            } else {
              notify("Something went wrong");
            }
          }
        })
        .catch((e) => {
          notify("Something went wrong");
        })
        .finally(() => {
          setBtnLoader(false);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleForgotPassword();
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${LoginPic})`,
      }}
      className="mainBackground"
    >
      <div className="cardLogin">
        <img src={LogoFroth} className="loginLogo" />
        <input
          className={email.length ? "inputWithValue inputLogin" : "inputLogin"}
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <button className="loginBtn" onClick={() => handleForgotPassword()}>
          {" "}
          {btnLoader ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress className="spinnerLoader" color="inherit" />
            </Box>
          ) : (
            "Send Reset Email"
          )}
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
