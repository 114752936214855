import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/App.css";
import Signup from "./pages/acounts/Signup";
import Auth from "./pages/acounts/auth";
import ForgotPassword from "./pages/acounts/forgotpassword";
import DeleteAccount from "./pages/dashboard/DeleteAccount";
import Home from "./pages/dashboard/Home";
import PrivacyPolicy from "./pages/dashboard/PrivacyPolicy";
import Terms from "./pages/dashboard/Terms";
import Tags from "./shared/components/Tags";
import ReportFeedBack from "./shared/components/feedBackreeport/ReportFeedBack";
import Chat from "./shared/components/main dashbord/Chat";
import Dash from "./shared/components/main dashbord/Dash";
import Groups from "./shared/components/main dashbord/Groups";
import Weather from "./shared/components/main dashbord/Weather";
import ProductsTable from "./shared/components/products/ProductsTable";
import ReportsTable from "./shared/components/reports/ReportsTable";
import AdminsTable from "./shared/components/users/AdminsTable";
import UsersTable from "./shared/components/users/UsersTable";
import FavouriteBeaches from "./shared/components/users/favouriteBeaches";
import Notify from "./shared/utils/notify";
import ResetPassword from "./pages/acounts/resetPassword";

function App() {
  const user = useSelector((state) => state.user.user);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate replace to="/dashboard/dash" /> : <Auth />}
        />
        <Route
          path="/forgot-password"
          element={
            user ? (
              <Navigate replace to="/dashboard/dash" />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path="/reset-password"
          element={
            user ? <Navigate replace to="/dashboard/dash" /> : <ResetPassword />
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route
          path="/delete-account-instructions"
          element={<DeleteAccount />}
        />

        {user ? (
          <Route path="/dashboard" element={<Home />}>
            <Route path="dash" element={<Dash />} />
            <Route path="weather" element={<Weather />} />
            <Route path="chat" element={<Chat />} />
            <Route path="groups" element={<Groups />} />
            <Route path="userstable" element={<UsersTable open={true} />} />
            {user?.admin?.role === "superAdmin" ? (
              <Route path="adminlist" element={<AdminsTable open={true} />} />
            ) : (
              <Route path="*" element={<Navigate to="/" />} />
            )}
            <Route path="reporttable" element={<ReportsTable open={true} />} />
            <Route
              path="producttable"
              element={<ProductsTable open={true} />}
            />
            <Route path="tags" element={<Tags open={true} />} />
            <Route
              path="feedReport"
              element={<ReportFeedBack open={true} title="Reported Profiles" />}
            />
            <Route
              path="reportedUser"
              element={<ReportFeedBack open={true} title="Reported Chats" />}
            />
            <Route
              path="favouriteBeaches"
              element={
                <FavouriteBeaches open={true} title="Favourite Beaches" />
              }
            />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
      <Notify />
    </div>
  );
}

export default App;
